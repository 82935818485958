import request from "@utils/request";

export function getAlertHubTableData(query) {
    return request({
        url: "alert-hub",
        method: "post",
        data: query,
    });
}

export function getRiskLevelOption() {
    return request({
        url: "alert-hub/risk-level/option",
        method: "get",
    });
}

export function getRiskLevelData(query) {
    return request({
        url: "alert-hub/risk-level",
        method: "get",
        params: query,
    });
}

export function changeRiskLevelData(query) {
    return request({
        url: "alert-hub/risk-level",
        method: "post",
        data: query,
    });
}

export function getAlertHubTableGroupedData(query) {
    return request({
        url: "alert-hub/grouped",
        method: "post",
        data: query,
    });
}

export function revertRiskLevelData(query) {
    return request({
        url: "alert-hub/risk-level",
        method: "delete",
        data: query,
    });
}
